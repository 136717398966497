/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.modalCustom .ant-modal-footer {
  background: #fff;
}
.wrapper_places {
  display: flex;
}
.wrapper_places > img {
  margin-right: 10px;
  cursor: pointer;
}
.wrapper_placesText {
  display: block;
  font-size: 14px;
  line-height: 25px;
  color: #000;
}
.tableListForm {
  width: 100%;
  padding: 10px 32px;
  background-color: #fff;
  display: inline-grid;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 1px 3px 0px #00000033;
  border-bottom: 1px solid #00000033;
}
.tableListForm .ant-form-item {
  margin-bottom: 15px;
  margin-right: 0;
  display: flex;
}
.tableListForm .ant-form-item > .ant-form-item-label {
  width: auto;
  line-height: 32px;
  padding-right: 8px;
}
.tableListForm .ant-form-item .ant-form-item-label > label {
  font-size: 12px;
}
.tableListForm .ant-form-item .ant-form-item-control {
  line-height: 32px;
}
.tableListForm .ant-form-item-control-wrapper {
  flex: 1;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.modalResizeImg .ant-modal-body,
.modalResizeImg .ant-modal-footer,
.modalResizeImg .ant-card-body {
  background: #fff;
}
.wrapperUserAddress {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.wrapperUserAddress_label {
  width: 110px;
  font-size: 14px;
  margin-right: 20px;
  color: #000;
}
.modalUserAddress {
  top: 20px;
}
.wrapper_titleList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapperUserAddress_fullName {
  font-weight: 500;
  margin-right: 20px;
  font-size: 16px;
}
.modalUserAddress .ant-list-item-action .actionList {
  color: #2673dd;
  font-size: 14px;
  font-weight: 400;
}
.modalUserAddress .ant-switch-checked {
  background-color: #16b512 !important;
}
.modalUserAddress .formItemSwitch {
  margin: 0 0 5px;
}
.modalUserAddress .textAdress {
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  margin-left: 15px;
  color: #000;
}
.wrapperSwitch {
  display: flex;
  align-items: center;
}
.wrapperSwitch:not(:last-child) {
  margin-bottom: 5px;
}
.tagDefault {
  color: #40d0bd;
  background-color: #e8f9f7;
}
.tagPickUp {
  color: #ee4d2d;
  background-color: #fff1f0;
}
.tagReturn {
  color: #eda500;
  background-color: #fff7e0;
  margin-top: 5px;
}
.wrapperSwitch > span {
  font-size: 14px;
  color: #000;
  margin-left: 15px;
}
.modalUserAddress .ant-modal-body,
.modalUserAddress .ant-modal-footer {
  background: #fff !important;
}
.icon {
  font-size: 20px;
  padding: 5px;
  right: 20px;
  border-radius: 50px;
  z-index: 5;
  cursor: pointer;
  background: #eee;
  box-shadow: #333333 2px 0px 5px 0px;
  position: absolute;
  top: -180px;
  right: -70px;
}
.dropDownCustomStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 118px;
  border: 1px solid #E0E7EE;
  border-radius: 4px;
  height: 34px;
}
.menu_icon {
  padding: 0;
}
.menu_icon .ant-dropdown-menu-item {
  padding: 10px 17px;
  color: #252525;
}
.menu_icon .ant-dropdown-menu-item:hover {
  background: #F6F7F9 0% 0% no-repeat padding-box;
}
.menu_icon .ant-dropdown-menu-item:hover div {
  color: #252525;
}
.dropDownCustomStatus:hover {
  border: 1px solid #E0E7EE;
}
.btn_status1 {
  background: transparent linear-gradient(180deg, #04C500 0%, #008100 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  margin: 0 auto;
}
.btn_status1:hover {
  background: transparent linear-gradient(180deg, #04C500 0%, #008100 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}
.btn_status1:active {
  background: transparent linear-gradient(180deg, #04C500 0%, #008100 100%) 0% 0% no-repeat padding-box !important;
  color: #fff;
}
.btn_status1:visited {
  background: transparent linear-gradient(180deg, #04C500 0%, #008100 100%) 0% 0% no-repeat padding-box !important;
  color: #fff;
}
.btn_status1:focus {
  background: transparent linear-gradient(180deg, #04C500 0%, #008100 100%) 0% 0% no-repeat padding-box !important;
  color: #fff;
}
.btn_statusAn {
  background: #F6F7F9 0% 0% no-repeat padding-box;
  color: #252525;
  margin: 0 auto;
}
.btn_statusAn:hover {
  background: #F6F7F9 0% 0% no-repeat padding-box;
  color: #252525;
}
.btn_statusAn:active {
  background: #F6F7F9 0% 0% no-repeat padding-box !important;
  color: #252525;
}
.btn_statusAn:visited {
  background: #F6F7F9 0% 0% no-repeat padding-box !important;
  color: #252525;
}
.btn_statusAn:focus {
  background: #F6F7F9 0% 0% no-repeat padding-box !important;
  color: #252525;
}
.btn_status0 {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #252525;
  margin: 0 auto;
}
.btn_status0:hover {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #252525;
}
.btn_status0:active {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  color: #252525;
}
.btn_status0:visited {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  color: #252525;
}
.btn_status0:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  color: #252525;
}
.dropDownCustomStatus img {
  margin-left: 12px;
}
.pagiCustom {
  text-align: right;
  background: #fff !important;
  padding: 10px 0;
}
.pagiCustom .ant-pagination-prev,
.pagiCustom .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  line-height: 22px;
}
.pagiCustom .ant-select-selector {
  align-items: center !important;
  height: 24px !important;
}
.pagiCustom .ant-pagination-item {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.btn_edit {
  color: #1175BB;
  background-color: #fff!important;
  border-color: #1175BB;
  padding: 6px 10px!important;
  font-weight: 500!important;
  min-width: 65px !important;
}
.menu_icon {
  padding: 0;
}
.menu_icon .ant-dropdown-menu-item {
  padding: 10px 17px;
  color: #252525;
}
.menu_icon .ant-dropdown-menu-item:hover {
  background: #F6F7F9 0% 0% no-repeat padding-box;
  color: #252525 !important;
}
.listCommentCms {
  background: #fff;
  padding: 10px 5px;
  max-height: 69vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.listCommentCms_comment {
  color: #000;
  font-size: 16px;
  margin-bottom: 20px;
}
.listCommentCms .ant-list-items {
  display: flex;
  flex-wrap: wrap;
}
.listCommentCms_img {
  width: 100%;
  height: 69px;
  object-fit: cover;
}
.listCommentCms_name {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.listCommentCms .ant-list-item-meta-title {
  margin: 0;
}
.listCommentCms .ant-list-items .ant-list-item {
  width: 50%;
}
.listCatalog .ant-list-item-meta {
  align-items: center;
}
.listCommentCms_productName {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.listCommentCms_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listCommentCms_des {
  display: flex;
  align-items: center;
}
.listCommentCms_date {
  margin-left: 15px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}
.dropDownCustom {
  margin-left: 15px;
}
.dropDownCustom:hover {
  border: 1px solid #E0E7EE;
}
.buttonThemMoi {
  background: #16b512;
  border: 1px solid #16b512;
  color: #fff;
}
.buttonThemMoi:hover {
  background: #159412;
  border: 1px solid #159412;
  color: #fff;
}
.btn_edit:hover {
  color: #1175BB;
  background-color: #fff;
  border-color: #1175BB;
}
.btn_edit:active {
  color: #1175BB;
  background-color: #fff;
  border-color: #1175BB;
}
.btn_edit:visited {
  color: #1175BB;
  background-color: #fff;
  border-color: #1175BB;
}
.btn_edit:focus {
  color: #1175BB;
  background-color: #fff;
  border-color: #1175BB;
}
.tabCustom .ant-tabs-nav {
  background: #fff;
  margin: 0;
  margin-bottom: 1px;
  padding: 0 8px;
}
.buttonSubmit {
  margin-right: 0px;
  float: right;
}
.modalTrang .ant-modal-content {
  background: #fff;
}
.modalFilter {
  margin: 0;
}
.modalFilter .ant-modal-content {
  border-radius: 0;
  background: #fff;
}
.modalFilter .ant-form-item-label {
  padding: 0;
}
.modalFilter .buttonSubmit {
  margin-top: 10px;
  margin-right: 0;
}
.buttonModalFilter {
  padding: 5px;
  background: #fff;
  cursor: pointer;
  display: none;
  border-bottom: 1px solid #00000033;
  box-shadow: 0px 1px 3px 0px #00000033;
}
.red td:first-child {
  border-left: 4px solid red;
}
.yellow td:first-child {
  border-left: 4px solid yellow;
}
.green td:first-child {
  border-left: 4px solid green;
}
.red6 td:nth-child(6) {
  border-left: 4px solid red;
  background: #ffc9c9 !important;
}
.yellow6 td:nth-child(6) {
  border-left: 4px solid yellow;
  background: #f9f3c0 !important;
}
.green6 td:nth-child(6) {
  border-left: 4px solid green;
  background: #77d677 !important;
}
.footer {
  background-color: #fafafa;
}
@media screen and (max-width: 992px) {
  .tableListForm .ant-form-item {
    padding: 0px;
  }
}
@media screen and (max-width: 768px) {
  .tableListForm .ant-form-item {
    padding: 0px;
    margin-right: 8px;
  }
  .buttonSubmit {
    margin-right: 8px;
  }
}
@media screen and (max-width: 576px) {
  .col_card_custom {
    margin-top: 15px;
  }
  .tableListForm {
    display: none;
  }
  .tableListForm .ant-form-item {
    padding: 0px 24px;
    margin-right: 0px;
  }
  .buttonSubmit {
    margin-right: 24px;
  }
  .buttonModalFilter {
    display: block;
    font-weight: 600;
  }
  .renderTree > .dragtreeTitle > .row_8 {
    position: relative;
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .renderTree > .dragtreeTitle .row_3 {
    position: relative;
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .renderTree > .dragtreeTitle .row_5 {
    display: block;
    flex: 0 0 16.833%;
    max-width: 16.833%;
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  .renderTree {
    width: 100%;
    overflow-x: auto;
  }
  .renderTree > .ant-spin-nested-loading {
    position: relative;
    width: 200%;
    overflow-x: auto;
  }
  .renderTree .dragtreeTitle {
    padding: 8px 15px 8px 40px;
    background: #1175BB !important;
    color: #ffffff;
    width: 200%;
    overflow-x: auto;
  }
  .listCommentCms .ant-list-items .ant-list-item {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .wrapper_titleList {
    align-items: unset;
    flex-direction: column-reverse;
  }
  .wrapper_titleList .listCommentCms_btn {
    margin-bottom: 5px;
  }
  .wrapper_titleList .dropDownCustomStatus {
    margin: 0 !important;
    margin-right: auto !important;
  }
  .listCommentCms .ant-list-items .ant-list-item {
    width: 100%;
  }
  .listCommentCms_img {
    width: 50px;
  }
  .renderTree > .dragtreeTitle > .row_8 {
    position: relative;
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .renderTree > .dragtreeTitle .row_3 {
    display: block;
    flex: 0 0 19.5%;
    max-width: 19.5%;
    position: relative;
  }
  .renderTree > .dragtreeTitle .row_5 {
    display: block;
    flex: 1 0 13.833%;
    max-width: 13.833%;
    position: relative;
  }
  .row_custom {
    width: 100%;
    line-height: 30px;
  }
  .row_custom > .row_8 {
    position: relative;
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row_custom .row_3 {
    display: block;
    flex: 0 0 19.5%;
    max-width: 19.5%;
    position: relative;
  }
  .row_custom .row_5 {
    display: block;
    flex: 1 0 13.833%;
    max-width: 13.833%;
    position: relative;
  }
}
td.ant-table-column-sort {
  background: inherit;
}
.rowChecked {
  background-color: #E5E5E5;
  border-radius: 15px;
  color: black;
  font-weight: 500;
  height: 50px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.rowUnCheck {
  height: 50px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.note {
  padding: 0 15px;
}
.modalCloseRed .ant-modal-close-x {
  font-size: 1.5rem;
}
.modalCloseRed .ant-modal-content {
  background-color: #ECEFF4;
}
.modalCloseRed .ant-modal-title {
  font-size: 20px;
}
.dragtree {
  padding: 0px 15px 5px !important;
}
.dragtree .ant-tree-treenode {
  border-top: 1px solid #eee;
  padding: unset !important;
}
.dragtree .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  padding: 3px 0;
}
.dragtree .ant-tree-node-content-wrapper:hover {
  background-color: #E6F8FE !important;
  padding: 0 5px;
  border-radius: 5px;
}
.dragtreeTitle {
  padding: 8px 15px 8px 40px;
  background: #1175BB !important;
  color: #ffffff;
}
.noData {
  background: #fff;
  padding: 20px;
  text-align: center;
  font-size: 1rem;
}
.modalHome {
  width: 50% !important;
}
@media screen and (max-width: 576px) {
  .modalHome {
    width: 100% !important;
  }
}
.listPlace {
  background: #fff;
  border-radius: 10px;
}
.listItemPlace {
  border-top: 1px solid #eee;
  padding: 5px 10px;
}
.listItemPlaceIcon {
  float: right;
}
.listItemPlaceIcon a {
  margin-left: 10px;
}
.descriptionWrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}
.descriptionLabel {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
.salesExtra a {
  color: rgba(0, 0, 0, 0.85);
  margin-left: 24px;
}
.salesExtra a:hover {
  color: #1175BB;
}
.salesExtra a.currentDate {
  color: #1175BB;
}
