/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content {
  margin: auto;
  width: 92%;
  padding-top: 43px;
}
.RowhelpKey {
  margin: 15px 0;
}
.layout {
  background: #e0e8ef;
}
.helpKey {
  border: 2px solid #777;
  border-radius: 4px;
  padding: 5px;
  margin-right: 15px;
  display: inline-block;
  min-width: 35px;
  font-weight: bold;
  color: #777;
  background: #fff;
  text-align: center;
}
.ant-btn {
  border-radius: 3px !important;
  border: 1px solid #2d6b95;
}
.ant-btn-primary {
  background: #1175BB !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5px;
}
.ant-btn-danger {
  border: 1px solid red;
  background: transparent linear-gradient(180deg, #FE5453 0%, #CB2222 100%) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 5px #00000029;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  opacity: 0.8;
}
.ant-btn[disabled] {
  background: #f5f5f5 !important;
}
.ant-legacy-form-item {
  margin-bottom: 15px;
  width: 100% !important;
}
.ant-form-item-label > label,
.ant-legacy-form-item-label > label {
  font-weight: 500;
  font-size: 14px !important;
}
.ant-table-pagination.ant-pagination {
  margin: 10px 0 !important;
}
.ant-message-notice {
  padding: 0px;
}
.ant-message-notice-content {
  width: 50vw;
  padding: 0px;
}
.ant-message-error {
  background-color: #fff1f0 !important;
  border: 1px solid#ffa39e !important;
  padding: 10px 0;
  width: 100%;
}
.ant-message-success {
  border: 1px solid#b7eb8f !important;
  background-color: #f6ffed !important;
  padding: 10px 0;
  width: 100%;
}
.ant-message-warning {
  border: 1px solid#ffe58f !important;
  background-color: #fffbe6 !important;
  padding: 10px 0;
  width: 100%;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  width: 6px;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled):not(.ant-select-item-option-selected),
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  color: #fff;
}
.ant-switch-handle {
  right: unset!important;
  left: 2px!important;
}
.ant-switch-checked {
  background-color: #34c38f !important;
}
.ant-switch-checked .ant-switch-handle {
  left: unset!important;
  right: 2px!important;
}
.ant-menu-submenu-popup ul li {
  border-bottom: 1px solid #ccc;
}
.ant-dropdown-menu-item-active {
  color: #fff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  color: #fff !important;
}
.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: #fff !important;
}
.ant-select-item-option {
  border-top: 1px solid #f0f0f0;
}
.ant-select-item-option-content {
  text-overflow: unset !important;
  white-space: unset !important;
}
.ant-modal {
  max-width: unset;
  padding-bottom: 0;
}
.ant-modal-close-x {
  font-size: 1.5rem;
}
.ant-modal-content {
  background-color: #ECEFF4;
  border-radius: 10px;
  box-shadow: unset;
}
.ant-modal-title {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .content {
    width: unset;
    margin: 25px 24px 0;
  }
}
