/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-page-header-index-pageHeader {
  background: #fff;
  padding: 10px 32px 0 32px;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0px 2px 4px #0f223a1f;
  border-radius: 4px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-wide {
  max-width: 1200px;
  margin: auto;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-detail {
  display: flex;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-row {
  display: flex;
  width: 100%;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-breadcrumb {
  margin-bottom: 10px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-tabs {
  margin: 0 0 0 -8px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-tabs .ant-tabs-bar {
  border-bottom: none;
  margin-bottom: 1px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-logo {
  flex: 0 1 auto;
  margin-right: 16px;
  padding-top: 1px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-logo > img {
  width: 28px;
  height: 28px;
  border-radius: 2px;
  display: block;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action {
  margin-left: 56px;
  min-width: 266px;
  display: flex;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action .ant-btn-group:not(:last-child),
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action .ant-btn:not(:last-child) {
  margin-right: 8px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-singleAction {
  margin-left: 56px;
  min-width: 100px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-singleAction .ant-btn-group:not(:last-child),
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-singleAction .ant-btn:not(:last-child) {
  margin-right: 8px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-singleAction .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-title,
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-content {
  flex: auto;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action,
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-extraContent,
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-main {
  flex: 0 1 auto;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-main {
  width: 100%;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-title,
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action {
  margin-bottom: 10px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-logo,
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-content,
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-extraContent {
  margin-bottom: 10px;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action,
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-extraContent {
  text-align: right;
}
.antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-extraContent {
  margin-left: 88px;
  min-width: 242px;
}
.antd-pro-component-dashs-page-header-index-anchor {
  padding-left: 0 !important;
}
@media screen and (max-width: 1200px) {
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-row {
    display: block;
  }
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action,
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-extraContent {
    margin-left: 0;
    text-align: left;
  }
  .antd-pro-component-dashs-page-header-index-singleAction {
    margin-left: 0px !important;
  }
  .antd-pro-component-dashs-page-header-index-singleAction .ant-btn {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-detail {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-component-dashs-page-header-index-pageHeader {
    padding: 10px 10px 0px 10px;
  }
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action .ant-btn-group,
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .antd-pro-component-dashs-page-header-index-pageHeader .antd-pro-component-dashs-page-header-index-action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
  .antd-pro-component-dashs-page-header-index-singleAction .ant-btn {
    font-size: 11px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
}
