/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-global-header-index-header {
  height: 43px;
  padding: 0;
  background: #1175BB !important;
  color: #fff !important;
  position: relative;
  z-index: 1;
}
.antd-pro-component-dashs-global-header-index-menuAvatar {
  text-align: center;
}
.antd-pro-component-dashs-global-header-index-logo {
  height: 43px;
  line-height: 43px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.antd-pro-component-dashs-global-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
}
.antd-pro-component-dashs-global-header-index-menu .anticon {
  margin-right: 8px;
}
.antd-pro-component-dashs-global-header-index-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.antd-pro-component-dashs-global-header-index-select {
  width: 200px;
}
.antd-pro-component-dashs-global-header-index-none {
  display: none;
}
.antd-pro-component-dashs-global-header-index-trigger {
  font-size: 20px;
  height: 43px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: calc((43px - 20px) / 2) 24px;
}
.antd-pro-component-dashs-global-header-index-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-component-dashs-global-header-index-right {
  float: right;
  height: 100%;
  width: auto;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-action > i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.85);
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-search {
  padding: 0 12px;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-search:hover {
  background: transparent;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-account .antd-pro-component-dashs-global-header-index-avatar {
  margin: calc((43px - 30px) / 2) 0;
  margin-right: 8px;
  color: #1175BB;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
  width: 30px;
  height: 30px;
}
.antd-pro-component-dashs-global-header-index-dark {
  height: 43px;
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action > i {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action:hover,
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action.opened {
  background: #1175BB;
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-component-dashs-global-header-index-inboxIcon {
  font-size: 16px;
}
@media only screen and (max-width: 768px) {
  .antd-pro-component-dashs-global-header-index-header .ant-divider-vertical {
    vertical-align: unset;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-name {
    display: none;
  }
  .antd-pro-component-dashs-global-header-index-header i.antd-pro-component-dashs-global-header-index-trigger {
    padding: 22px 12px;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #1175BB;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-account .antd-pro-component-dashs-global-header-index-avatar {
    margin-right: 0;
  }
}
