/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-top-nav-header-index-head {
  width: 100%;
  transition: background 0.3s, width 0.2s;
  height: 43px;
  position: relative;
  z-index: 1;
  background: #1175BB !important;
  color: #fff;
}
.antd-pro-component-dashs-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 43px;
  height: 100%;
  font-size: 12px;
  margin: 0 10px;
}
.antd-pro-component-dashs-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.antd-pro-component-dashs-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title span {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.antd-pro-component-dashs-top-nav-header-index-head .antd-pro-component-dashs-top-nav-header-index-main {
  display: flex;
  height: 43px;
  margin: auto;
  width: 95%;
  padding: 0 5px;
}
.antd-pro-component-dashs-top-nav-header-index-head .antd-pro-component-dashs-top-nav-header-index-main.antd-pro-component-dashs-top-nav-header-index-wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.antd-pro-component-dashs-top-nav-header-index-head .antd-pro-component-dashs-top-nav-header-index-main .antd-pro-component-dashs-top-nav-header-index-left {
  flex: 1;
  display: flex;
  width: 75%;
  float: left;
}
.antd-pro-component-dashs-top-nav-header-index-logo {
  width: 30%;
  height: 43px;
  position: relative;
  line-height: 43px;
  transition: all 0.3s;
  overflow: hidden;
}
.antd-pro-component-dashs-top-nav-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
}
.antd-pro-component-dashs-top-nav-header-index-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.antd-pro-component-dashs-top-nav-header-index-light h1 {
  color: #002140;
}
.antd-pro-component-dashs-top-nav-header-index-menu {
  border: none;
  height: 43px;
  line-height: 43px;
  width: 100%;
}
